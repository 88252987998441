import {
  Carousel,
  CarouselContent,
  CarouselNext,
  CarouselPrevious,
  type CarouselApi,
} from "@/components/atoms/shadcn/ui/carousel";
import { useMKTActivityData } from "@/hooks";
import { useEffect, useState } from "react";
import { CarouselActivitiesItem } from "./CarouselActivitiesItem";
import { ActivitiesDatum } from "@/interfaces/CampaignsWithDetailsInterface";
import {
  CardNumOverview,
  WasteValidationsContainer,
} from "@/components/molecules";
import { FilterPerYearController } from "../FilterPerYearController/FilterPerYearController";
import { ComplementData } from "../DataOverviewActivity/ComplementData";
import AutoHeight from "embla-carousel-auto-height";

type DataArray = {
  [key: string]: {
    name_graphic_data: string;
    nameFilter: string;
    title: string;
  }[];
};

const dataArray: DataArray = {
  validacion_de_residuos: [
    {
      name_graphic_data: "data_1",
      nameFilter: "globalRange",
      title: "Kg acumulados",
    },
    {
      name_graphic_data: "data_1",
      nameFilter: "globalRange",
      title: "Usuarios Activos",
    },
    {
      name_graphic_data: "data_1",
      nameFilter: "globalRange",
      title: "Usuarios con TyC",
    },
    {
      name_graphic_data: "data_1",
      nameFilter: "globalRange",
      title: "Prom. de Validaciones por usuario",
    },
    {
      name_graphic_data: "data_1",
      nameFilter: "globalRange",
      title: "Prom. de Kg validados por usuario",
    },
    {
      name_graphic_data: "data_1",
      nameFilter: "globalRange",
      title: "Prom. de Kg por validación",
    },
    {
      name_graphic_data: "data_1",
      nameFilter: "globalRange",
      title: "No. de acopios participantes",
    },
  ],
  escaneo_productos: [
    {
      name_graphic_data: "data_1",
      nameFilter: "globalRange",
      title: "Usuarios Activos",
    },
    {
      name_graphic_data: "data_1",
      nameFilter: "globalRange",
      title: "Usuarios con TyC",
    },
    {
      name_graphic_data: "data_1",
      nameFilter: "globalRange",
      title: "No. de acopios y/o puntos participantes",
    },
    {
      name_graphic_data: "data_1",
      nameFilter: "globalRange",
      title: "No. Total Validaciones",
    },
    {
      name_graphic_data: "data_1",
      nameFilter: "globalRange",
      title: "Prom. de Validaciones por usuario",
    },
  ],
  take_photo: [],
};

export const CarouselActivitiesOverview = ({
  activities,
  onlyCards,
}: {
  activities: ActivitiesDatum[];
  onlyCards?: boolean;
}) => {
  const [api, setApi] = useState<CarouselApi>();
  const { control_filters, reset_filters, stats } = useMKTActivityData([], []);
  const [activity, setActivity] = useState<ActivitiesDatum>();

  const handleSelect = () => {
    if (!api) return;
    const currentIndex = api.selectedScrollSnap();
    const currentSlide = activities[currentIndex];
    if (!currentSlide) return;
    setActivity(activities.find((item) => item.id === currentSlide.id));
  };

  useEffect(() => {
    if (!api) return;
    api.on("select", handleSelect);
    handleSelect();
    return () => {
      api.off("select", handleSelect);
    };
  }, [api, activities]);

  useEffect(() => {
    setActivity(activities[0]);
  }, [activities]);

  if (!activity) {
    return null;
  }

  return (
    <div className="flex w-full justify-center px-10 items-center">
      <Carousel
        setApi={setApi}
        opts={{ loop: false, align: "start" }}
        plugins={[AutoHeight()]}
      >
        <CarouselContent
          style={{
            display: "flex",
            alignItems: "flex-start",
            marginLeft: "-8px",
            marginRight: "8px",
          }}
        >
          <CarouselActivitiesItem
            control={control_filters}
            activity={{
              ...activities[0],
              activity_type: "general",
            }}
          />
          {activities.map((activity) => (
            <CarouselActivitiesItem
              key={activity.id}
              control={control_filters}
              activity={activity}
            />
          ))}
        </CarouselContent>
        <CarouselPrevious className="bg-white fill-white hidden lg:flex" />
        <CarouselNext className="bg-white fill-white hidden lg:flex" />
      </Carousel>
    </div>
  );
};
