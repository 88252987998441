import {
  Waste,
  WasteCategoriesId,
  WasteProperty,
  Waste_Register,
} from "@/interfaces";
import { useSession } from "next-auth/react";
import { useFieldArray, useForm } from "react-hook-form";
import { dataServices } from "./dataServices";
import { useQuery } from "@tanstack/react-query";
import { WasteCategory } from "@/interfaces/skuInterface";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { serialize } from "object-to-formdata";
import { toast } from "@/components/atoms/shadcn/ui/use-toast";
import { useEffect, useState } from "react";

const NewWasteCategorySchema = z.object({
  name: z.string().min(1),
});

const NewWasteSchema = z.object({
  type: z.string().optional(),
  waste_informations: z.array(
    z.object({
      info: z.string().optional(),
      waste_property_id: z.string().optional(),
      _destoy: z.boolean(),
    })
  ),
  waste_category_wastes: z
    .array(
      z.object({
        info: z.string().optional(),
        waste_category_id: z.string().optional(),
        _destoy: z.boolean(),
      })
    )
    .optional(),
  waste_category_wastes_id: z.string({
    required_error: "Categoría es necesaria",
  }),
  name: z
    .string({ required_error: "El Nombre de Residuos es Requerido" })
    .min(1, "El NOMBRE del RESIDUO debe ser más Largo"),
  description: z.string({ required_error: "La Descripción es Requerida" }),
  platform_visible: z.string().optional(),
  slug: z.string().optional(),
  icon: z.any().optional(),
  id: z.string().optional(),
  active: z.boolean().optional(),
  selected: z.boolean().optional(),
  file: z.any().optional(),
});

const UpdateWasteSchema = z.object({
  type: z.string().optional(),
  waste_informations: z.record(
    z.string(),
    z.object({
      data: z.string().optional(),
      waste_property_id: z.string().optional(),
      _destroy: z.boolean().optional(),
    })
  ),
  waste_category_wastes: z
    .array(
      z.object({
        info: z.string().optional(),
        waste_category_id: z.string().optional(),
        _destroy: z.boolean().optional(),
      })
    )
    .optional(),
  waste_category_wastes_id: z.string({
    required_error: "Categoría es necesaria",
  }),
  name: z
    .string({ required_error: "El Nombre de Residuos es Requerido" })
    .min(1, "El NOMBRE del RESIDUO debe ser más Largo"),
  description: z.string({ required_error: "La Descripción es Requerida" }),
  platform_visible: z.string().optional(),
  slug: z.string().optional(),
  icon: z.any().optional(),
  id: z.string(),
  active: z.boolean().optional(),
  selected: z.boolean().optional(),
  file: z.any().optional(),
});

function getWasteCategoryArray(
  waste_category_wastes: {
    id?: string | undefined;
    waste_category_id?: string | undefined;
    _destroy: boolean;
  }[],
  waste_category_wastes_id?: string | undefined
) {
  let wasteCategoriesArray = waste_category_wastes.map((uni_cate) => {
    return {
      id: uni_cate.id,
      waste_category_id: uni_cate.waste_category_id,
      _destroy:
        uni_cate.waste_category_id !== waste_category_wastes_id ? true : false,
    };
  });

  if (
    wasteCategoriesArray.findIndex(
      (uni_wc) => uni_wc.waste_category_id == waste_category_wastes_id
    ) < 0
  ) {
    wasteCategoriesArray.push({
      id: undefined,
      waste_category_id: waste_category_wastes_id,
      _destroy: false,
    });
  }

  return wasteCategoriesArray;
}

export const useWasteRegister = ({ isNewWaste }: { isNewWaste: boolean }) => {
  const { data } = useSession();

  const { data: wastes, refetch: refetch_wastes } = useQuery<Waste[]>({
    queryKey: ["wastes"],
    enabled: data?.user?.token ? true : false,
    queryFn: async () => {
      const results = data?.user?.token
        ? await dataServices({
            url: "/wastes",
            params: {
              per_page: 300,
              show_inactive:true,
              "include_waste_category_wastes.waste_categories": true,
              "include_waste_informations.waste_properties": true,
            },
            apiVersion: "v2",
            token: data?.user?.token ?? undefined,
          })
        : null;
      const response: Waste[] = results.data;
      return response;
    },
  });

  const { data: wastes_categories, refetch: refetch_wastes_categories } =
    useQuery<WasteCategoriesId[]>({
      queryKey: ["wastes_categories"],
      enabled: data?.user?.token ? true : false,
      queryFn: async () => {
        const results = data?.user?.token
          ? await dataServices({
              url: "/waste_categories",
              apiVersion: "v2",
              token: data?.user?.token ?? undefined,
            })
          : null;
        return results.data.map((item: any) => {
          return {
            id: item.id,
            name: item.name,
          };
        });
      },
    });

  const { data: wasteProperties, refetch: refetch_waste_properties } = useQuery<
    WasteProperty[]
  >({
    queryKey: ["waste_properties"],
    enabled: data?.user?.token ? true : false,
    queryFn: async () => {
      const results = data?.user?.token
        ? await dataServices({
            url: "/waste_properties",
            token: data?.user?.token || "",
            apiVersion: "v2",
          })
        : null;
      const response: WasteProperty[] = results.data;
      return response;
    },
  });

  const [active_waste, setActive_waste] = useState("");

  useEffect(() => {
    reset_waste_data(active_waste);
  }, [active_waste]);

  const {
    control: control_waste,
    handleSubmit: handleSubmit_waste,
    watch: watch_waste,
    setValue,
    reset: reset_waste,
    getValues,
    formState: { errors: errors_waste },
  } = useForm<Waste_Register>({
    resolver: zodResolver(isNewWaste ? NewWasteSchema : UpdateWasteSchema),
    defaultValues: {
      type: "",
      waste_informations: {},
      waste_category_wastes:[],
      waste_category_wastes_id: undefined,
      name: "",
      description: "",
      platform_visible: "",
      slug: "",
      icon: undefined,
      id: "",
      file: undefined,
    },
  });

  const {
    fields: fields_categories,
    append: append_categories,
    remove: remove_categories,
  } = useFieldArray({
    name: "waste_category_wastes",
    control: control_waste,
  });

  function reset_waste_data(id_waste: string) {
    const waste_found = wastes?.find((uni_w) => uni_w.id === id_waste);
    reset_waste();
    setValue("description", waste_found?.description ?? "");
    setValue("active", waste_found?.active ?? true);
    setValue("icon", waste_found?.icon ?? null);
    setValue("id", waste_found?.id ?? "");
    setValue("name", waste_found?.name ?? "");
    setValue("platform_visible", waste_found?.platform_visible ?? "");
    setValue("selected", waste_found?.selected ?? false);
    setValue("slug", waste_found?.slug ?? "");
    setValue("type", waste_found?.type ?? "");
    waste_found?.waste_informations.data?.forEach((uni_wp, idx: number) => {
      setValue(
        `waste_informations.${uni_wp.waste_property.data.id}.data`,
        uni_wp.info ?? ""
      );
      setValue(
        `waste_informations.${uni_wp.waste_property.data.id}.id_waste_info`,
        uni_wp.id ?? ""
      );
      setValue(
        `waste_informations.${uni_wp.waste_property.data.id}._destroy`,
        false
      );
    });
    waste_found?.waste_category_wastes?.data.forEach((_: any, idx: number) => {
      append_categories({
        id: waste_found?.waste_category_wastes.data[idx].id,
        waste_category_id:
          waste_found?.waste_category_wastes.data[idx].waste_category.data.id,
        _destroy: false,
      });
      setValue(
        "waste_category_wastes_id",
        waste_found?.waste_category_wastes?.data[0]?.waste_category.data.id?? ""
      );
    });
    // console.log({ waste_found, waste_values : getValues() });

  }

  const {
    control: control_new_category,
    handleSubmit: handleSubmit_new_category,
    setValue: setValue_new_category,
    reset: reset_new_category,
    formState: { errors: errors_new_category },
  } = useForm<WasteCategoriesId>({
    resolver: zodResolver(NewWasteCategorySchema),
  });

  useEffect(() => {
    if (watch_waste("waste_category_wastes_id") == "new_category") {
      setIsOpenDialogNC(true);
    }
  }, [watch_waste("waste_category_wastes_id")]);

  const [isOpenDialogNC, setIsOpenDialogNC] = useState(false);

  function forceResetWaste(isNewCategory?: boolean) {
    if (!isNewCategory) {
      reset_waste();
      remove_categories();
      setActive_waste("");
    }
    refetch_wastes_categories();
    refetch_wastes();
    refetch_waste_properties();
  }

  async function handleNewCompany(dataCategory: WasteCategoriesId) {
    const object_to_update = {
      waste_category: {
        id: undefined,
        name: dataCategory.name,
      },
    };
    const formData = serialize(object_to_update);
    dataServices({
      url: "/waste_categories",
      token: data?.user?.token ?? undefined,
      requestType: "POST",
      body: formData,
      contentType: "multipart/form-data",
    })
      .then((response) => {
        if (response.status == "201") {
          toast({
            title: "Actualización de Categorías con ÉXITO",
            description: `Se ha actualizado los valores con éxito`,
            variant: "success",
          });
        } else {
          toast({
            title: "Ha ocurrido un error: Categorías",
            description: `No se ha podido actualizar los datos.`,
            variant: "destructive",
          });
        }
      })
      .catch((error) => {
        console.error(error);
        toast({
          title: "Ha ocurrido un error: Categorías",
          description: `No se ha podido actualizar los datos.`,
          variant: "destructive",
        });
      });
    forceResetWaste(true);
  }

  async function handleSaveGeneralInfo(dataWasteRegister: Waste_Register) {
    const key_wi = Object.keys(dataWasteRegister.waste_informations);
    let wi_array: any = [];
    key_wi.forEach((uni_key) => {
      if (
        dataWasteRegister.waste_informations[uni_key].data !== "" ||
        dataWasteRegister.waste_informations[uni_key].data !== undefined
      ) {
        wi_array.push({
          id: dataWasteRegister.waste_informations[uni_key].id_waste_info,
          info: dataWasteRegister.waste_informations[uni_key].data,
          waste_property_id: uni_key,
          _destroy: dataWasteRegister.waste_informations[uni_key]._destroy,
        });
      }
    });
    const object_to_update = {
      waste: {
        id: dataWasteRegister.id,
        waste_informations_attributes: wi_array,
        waste_category_wastes_attributes: getWasteCategoryArray(
          dataWasteRegister.waste_category_wastes,
          dataWasteRegister.waste_category_wastes_id
        ),
        active: true,
        platform_visible: "visible_web_visible_app",
        name: dataWasteRegister.name,
        description: dataWasteRegister.description,
        icon: dataWasteRegister.file ?? undefined,
      },
    };
    const formData = serialize(object_to_update);
    dataServices({
      url: `/wastes/${dataWasteRegister.id}`,
      token: data?.user?.token ?? undefined,
      requestType: "PATCH",
      body: formData,
      contentType: "multipart/form-data",
    })
      .then((response) => {
          toast({
            title: "Edición de Residuo con ÉXITO",
            description: `Se ha actualizado los valores con éxito`,
            variant: "success",
          });
      })
      .catch((error) => {
        console.error(error);
        toast({
          title: "Ha ocurrido un error: Edición de Residuo",
          description: `No se ha podido actualizar los datos.`,
          variant: "destructive",
        });
      });
    forceResetWaste();
  }

  async function handleSaveCompleteWaste(dataWasteRegister: Waste_Register) {
    const key_wi = Object.keys(dataWasteRegister.waste_informations);
    let wi_array: any = [];
    key_wi.forEach((uni_key) => {
      if (
        dataWasteRegister.waste_informations[uni_key].data !== "" ||
        dataWasteRegister.waste_informations[uni_key].data !== undefined
      ) {
        wi_array.push({
          id: dataWasteRegister.waste_informations[uni_key].id_waste_info,
          info: dataWasteRegister.waste_informations[uni_key].data,
          waste_property_id: uni_key,
          _destroy: dataWasteRegister.waste_informations[uni_key]._destroy,
        });
      }
    });
    const object_to_update = {
      waste: {
        id: dataWasteRegister.id,
        waste_informations_attributes: wi_array,
        waste_category_wastes_attributes: getWasteCategoryArray(
          dataWasteRegister.waste_category_wastes,
          dataWasteRegister.waste_category_wastes_id
        ),
        collection_center_wastes_attributes: [],
        active: true,
        platform_visible: "visible_web_visible_app",
        name: dataWasteRegister.name,
        description: dataWasteRegister.description,
        icon: dataWasteRegister.file ?? undefined,
      },
    };

    const formData = serialize(object_to_update);
    dataServices({
      url: "/wastes",
      token: data?.user?.token ?? undefined,
      requestType: "POST",
      body: formData,
      contentType: "multipart/form-data",
    })
      .then((response) => {
        if (response.status == "201") {
          toast({
            title: "Alta de Residuo con ÉXITO",
            description: `Se ha actualizado los valores con éxito`,
            variant: "success",
          });
        } else {
          toast({
            title: "Ha ocurrido un error:Alta de Residuo",
            description: `No se ha podido actualizar los datos.`,
            variant: "destructive",
          });
        }
      })
      .catch((error) => {
        console.error(error);
        toast({
          title: "Ha ocurrido un error: Alta de Residuo",
          description: `No se ha podido actualizar los datos.`,
          variant: "destructive",
        });
      });

    forceResetWaste();
  }

  return {
    data_session: data,
    wastes: wastes ?? [],
    wastes_categories: wastes_categories ?? [],
    wasteProperties: wasteProperties ?? [],
    refetch_wastes,
    refetch_wastes_categories,
    control_waste,
    handleSubmit_waste,
    watch_waste,
    setValue,
    reset_waste,
    errors_waste,
    getValues,
    reset_waste_data,
    handleSubmit_new_category,
    control_new_category,
    reset_new_category,
    errors_new_category,
    handleNewCompany,
    handleSaveGeneralInfo,
    handleSaveCompleteWaste,
    isOpenDialogNC,
    setIsOpenDialogNC,
    remove_categories,
    refetch_waste_properties,
    active_waste,
    setActive_waste,
  };
};
