import { apiBaseURLV1 } from "@/constants";
import {
  TypeMeasure,
  WasteCategoriesId,
  WasteId,
  product_wastes,
} from "@/interfaces";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { dataServices } from "./dataServices";
import type { Session } from "next-auth";

export const useWastesData = (data: Session | null) => {
  const { data: type_measure } = useQuery<TypeMeasure[]>({
    queryKey: ["type_measure"],
    enabled: data?.user?.token ? true : false,
    queryFn: async () => {
      const typesMeasure: TypeMeasure[] = [
        { id: "1", name: "ml" },
        { id: "2", name: "kg" },
        { id: "3", name: "g" },
        { id: "4", name: "L" },
        { id: "5", name: "mg" },
        { id: "6", name: "pz" },
        { id: "8", name: "cm" },
        { id: "9", name: "mm" },
      ];
      return typesMeasure;
    },
  });

  const { data: wastes } = useQuery<WasteId[]>({
    queryKey: ["wastes"],
    enabled: data?.user?.token ? true : false,
    queryFn: async () =>
      (
        await axios.get(`${apiBaseURLV1}/wastes`)
      ).data.data.flatMap((uniWaste: any) => {
        return {
          id: uniWaste.id,
          name: uniWaste.attributes.name,
        };
      }),
  });

  const { data: wastes_categories } = useQuery<WasteCategoriesId[]>({
    queryKey: ["wastes_categories"],
    enabled: data?.user?.token ? true : false,
    queryFn: async () => {
      const results = await dataServices({
        url: "/waste_categories",
        apiVersion: "v1",
        token: data?.user?.token ?? undefined,
      });
      return results.data.map((item: any) => {
        return {
          id: item.id,
          name: item.name,
        };
      });
    },
  });

  function getNamesOfWastes(wasteDetails: product_wastes[]): string {
    const idToNameMap: { [id: string]: string } = {};

    if (wastes) {
      // Construir un mapa de ID a nombre para búsquedas eficientes
      for (const waste of wastes) {
        idToNameMap[waste.id] = waste.name;
      }

      // Iterar sobre los detalles de residuos y construir el string de nombres
      const names: string[] = [];
      for (const detail of wasteDetails) {
        const name = idToNameMap[detail.waste_id];
        if (name) {
          names.push(name);
        }
      }

      return names.join(", ");
    } else {
      return wasteDetails.map((item) => item.waste_id).join(",");
    }
  }

  function getNamesOfWasteCategories(wasteDetails: product_wastes[]): string {
    const idToNameMap: { [id: string]: string } = {};
    if (wastes_categories) {
      // Construir un mapa de ID a nombre para búsquedas eficientes
      for (const waste of wastes_categories) {
        idToNameMap[waste.id] = waste.name;
      }

      // Iterar sobre los detalles de residuos y construir el string de nombres
      const names: string[] = [];
      for (const detail of wasteDetails) {
        const name = idToNameMap[detail.waste_category_id];
        if (name) {
          names.push(name);
        }
      }

      return names.join(", ");
    } else {
      return wasteDetails.map((item) => item.waste_category_id).join(",");
    }
  }

  function getMeasureName(measureId: string): string {
    const measure = type_measure?.find((measure) => measure.id === measureId);
    return measure ? measure.name : "";
  }

  return {
    type_measure: type_measure || [],
    wastes: wastes || [],
    wastes_categories: wastes_categories || [],
    getNamesOfWastes,
    getNamesOfWasteCategories,
    getMeasureName,
  };
};
