import { useSession } from "next-auth/react";
import { usePagination } from "./usePagination";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import {
  GridTicket,
  RejectedOptionsId,
  RejectedValidation,
  RowTicket,
  ShoppingPlacesId,
  Ticket,
  TicketResponse,
} from "@/interfaces";
import { ShoppingRegistrationUniItem } from "@/interfaces/ticketsInterface";
import { dataServices } from "./dataServices";
import { DateRange } from "react-day-picker";
import { useForm } from "react-hook-form";
import { api_v1 } from "@/shared/api";
import { CampaignsOptions } from "@/constants/campaignsData";
import { serialize } from "object-to-formdata";
import { useToast } from "@/components/atoms/shadcn/ui/use-toast";
export type GlobalFormDateValues = {
  date: string;
  place_shopping_id: string;
};
export function transform2GridTickets(arr: RowTicket): GridTicket {
  const result: any[][] = [];
  const chunkSize = 3;
  for (let i = 0; i < arr.length; i += chunkSize) {
    result.push(arr.slice(i, i + chunkSize));
  }
  return result;
}

function getDataToTicket(arr: any) {
  return arr.flatMap((dataTest: TicketResponse): Ticket => {
    return {
      id_ticket: dataTest.id ?? "",
      evidence: dataTest?.ticket ?? undefined,
      user_name:
        dataTest.recycler.data.first_name && dataTest.recycler.data.last_name
          ? `${dataTest.recycler.data.first_name} ${dataTest.recycler.data.last_name}`
          : dataTest.recycler?.data?.id,
      id_user: dataTest.recycler?.data?.id,
      date_insert: new Date(dataTest.created_at).toLocaleDateString("es-MX"),
      place: String(dataTest.shopping_place.data.id),
      place_name: dataTest.shopping_place.data.name,
      list_sku:
        dataTest.shopping_registration_item?.data?.map(
          (item: ShoppingRegistrationUniItem, idx: number) => {
            return {
              id: item.id,
              sku: item.barcode ?? undefined,
              brand: `${
                item.product?.data?.name ? `${item.product?.data?.name}, ` : ""
              }${
                item.product?.data?.marca
                  ? `${item.product?.data?.marca}, `
                  : ""
              }${
                item.product?.data?.submarca
                  ? `${item.product?.data?.submarca}, `
                  : ""
              }`,
              description: item.product?.data?.description ?? undefined,
              _destroy: false,
            };
          }
        ) ?? [],
      description: dataTest?.comment ?? undefined,
      sku: dataTest?.other_place ?? dataTest?.shopping_place?.data?.name,
    };
  });
}

export const useDataTicket = (per_page: number, type_validations: number) => {
  const { data } = useSession();
  const { toast, dismiss } = useToast();
  const { control, watch } = useForm<{
    globalRange: DateRange;
    search: string;
    place_shopping_id: string;
  }>({
    defaultValues: {
      globalRange: {
        from: undefined,
        to: undefined,
      },
      search: "",
      place_shopping_id: "",
    },
  });
  const {
    currentPage,
    firstPage,
    lastPage,
    nextPage,
    prevPage,
    last_page,
    paginationLabel,
    setTotal,
    setTotalPages,
    setCurrentPage,
    pageSize,
    onChangePageSize,
  } = usePagination(per_page);

  const { data: requestData, refetch } = useQuery({
    queryKey: ["ticket_grid", currentPage, type_validations, pageSize],
    enabled: data?.user?.token ? true : false,
    queryFn: async () => {
      const results = data?.user?.token
        ? await dataServices({
            url: "/shopping_registrations",
            params: {
              state:
                type_validations == 0
                  ? "pending"
                  : type_validations == 1
                  ? "accepted"
                  : "rejected",
              per_page: pageSize,
              page: currentPage,
              admins: true,
              "include_shopping_registration_item.product": true,
              include_recycler: true,
              include_shopping_place: true,
              start_date:
                watch("globalRange.from") !== undefined
                  ? watch("globalRange.from")?.toJSON()
                  : "",
              end_date:
                watch("globalRange.to") !== undefined
                  ? watch("globalRange.to")?.toJSON()
                  : "",
              search: watch("search") || "",
              shopping_place_id: watch("place_shopping_id") || "",
            },
            token: data?.user?.token ?? undefined,
          })
        : null;
      const data_product: RowTicket = getDataToTicket(results.data);
      return { data: data_product, results, meta: { ...results.meta } };
    },
  });

  const { data: totals, refetch: refetchTotals } = useQuery({
    queryKey: ["totals_ticket_grid"],
    enabled: data?.user?.token ? true : false,
    queryFn: async () => {
      return data?.user?.token
        ? await dataServices({
            url: "/shopping_registrations/overview_totals",
            token: data?.user?.token || "",
          })
        : null;
    },
  });

  const { data: rejectedOptions, refetch: rejectedRefetch } = useQuery<
    RejectedOptionsId[]
  >({
    queryKey: ["rejectedOptions"],
    enabled: data?.user?.token ? true : false,
    queryFn: async () => {
      const results = data?.user?.token
        ? await dataServices({
            url: "/rejected_reasons?type=shoppping_registration",
            apiVersion: "v2",
            token: data?.user?.token ?? undefined,
          })
        : null;
      return results.data.flatMap((uniWaste: any) => {
        return {
          id: uniWaste.id,
          name: uniWaste.description,
        };
      });
    },
  });

  const { data: shopping_places } = useQuery<CampaignsOptions[]>({
    queryKey: ["shopping_places"],
    enabled: data?.user?.token ? true : false,
    queryFn: async () => {
      const results = await api_v1.get(`/shopping_registrations/places`, {
        axiosOptions: {
          headers: {
            Authorization: data?.user?.token,
          },
        },
      });
      return results.data.map((item: any) => {
        return {
          id: item.id,
          name: item.name,
        };
      });
    },
  });

  useEffect(() => {
    if (requestData?.data) {
      setTotalPages(requestData?.meta.total_pages);
      setTotal(requestData?.meta.total_count);
    }
  }, [requestData?.meta]);

  async function forceRefetchTickets() {
    const idToast = toast({
      variant: "warning",
      description: "Espere un momento, se está cargando la información final",
      title: "Refrescando la información de la página",
    }).id;
    const response = await refetch();
    refetchTotals();
   
    if (response?.data?.data.length !== 0) {
      dismiss(idToast);
      toast({
        variant: "default",
        description: "Datos Actualizados",
        title: "Datos actualizados en la vista",
      });
    } else {
      dismiss(idToast);
      toast({
        variant: "destructive",
        description: "Error al actualizar la página",
        title: "Refresque la página para poder obtener de nuevo los datos.",
      });
    }
  }

  useEffect(() => {
    forceRefetchTickets();
  }, [
    watch("globalRange.from"),
    watch("globalRange.to"),
    watch("search"),
    watch("place_shopping_id"),
    type_validations,
    pageSize,
  ]);

  async function onSubmitValidTicket(dataTicket: Ticket) {
    const object_to_update = {
      shopping_registration: {
        shopping_registration_item_attributes: dataTicket.list_sku.map(
          (uniSKU) => {
            return {
              id: uniSKU.id,
              barcode: uniSKU.sku,
              _destroy: uniSKU._destroy,
            };
          }
        ),
        shopping_place_id: dataTicket.place,
        comment: dataTicket.description,
        state: "accepted",
      },
    };
    const formData = serialize(object_to_update);
    await dataServices({
      url: `/shopping_registrations/${dataTicket.id_ticket}`,
      requestType: "PATCH",
      body: formData,
      contentType: "multipart/form-data",
      apiVersion: "v2",
      token: data?.user?.token ?? undefined,
    });
    forceRefetchTickets();
  }

  async function onSubmitRejectedTicket(
    dataRejected: RejectedValidation,
    dataTicket: Ticket
  ) {
    const id_backend = dataTicket.id_ticket;
    const object_to_update = {
      shopping_registration: {
        shopping_registration_item: dataTicket.list_sku.map((uniSKU) => {
          return {
            id: uniSKU.id,
            barcode: uniSKU.sku,
            _destroy: uniSKU._destroy,
          };
        }),
        shopping_place_id: dataTicket.place,
        comment: dataTicket.description,
        rejected_reason_id: dataRejected.rejected_category,
        state: "rejected",
      },
    };
    const formData = serialize(object_to_update);
    await dataServices({
      url: `shopping_registrations/${id_backend}`,
      body: formData,
      contentType: "multipart/form-data",
      apiVersion: "v2",
      requestType: "PATCH",
      token: data?.user?.token ?? undefined,
    });
    forceRefetchTickets();
  }

  async function onSubmitNewRejectedOption(dataRejected: RejectedValidation) {
    const object_to_update = {
      rejected_reason: {
        description: dataRejected.new_rejected_category,
        rejected_reason_type: "shoppping_registration",
      },
    };
    const formData = serialize(object_to_update);
    await dataServices({
      url: `/rejected_reasons`,
      apiVersion: "v2",
      requestType: "POST",
      body: formData,
      contentType: "multipart/form-data",
      token: data?.user?.token ?? undefined,
    });
    rejectedRefetch();
  }

  async function onSubmitDeleteTicket(dataTicket: Ticket) {
    const id_backend = dataTicket.id_ticket;
    const resp = dataServices({
      url: `/shopping_registrations/${id_backend}`,
      requestType: "DELETE",
      apiVersion: "v2",
      token: data?.user?.token ?? undefined,
    });
    forceRefetchTickets();
  }

  return {
    totals,
    requestData,
    control_date: control,
    pagination: {
      currentPage,
      firstPage,
      lastPage,
      nextPage,
      prevPage,
      paginationLabel,
      last_page,
      onChangePageSize,
      pageSize,
    },
    rejectedOptions: rejectedOptions ?? [],
    shopping_places: shopping_places ?? [],
    onSubmitValidTicket,
    onSubmitRejectedTicket,
    onSubmitDeleteTicket,
    onSubmitNewRejectedOption,
  };
};
